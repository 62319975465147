import { Link } from '@/components/Link';
import { useStable } from '@/hooks/useStable';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneCheckout } from 'base/components/Checkout';
import Tracking from 'base/components/TrackingComponent';
import { getOcelotConfig } from 'lib/configs/ocelot';

const policy = (
  <>
    För att ta del av utmaningen kommer ett konto skapas till dig. Dina uppgifter kommer hanteras enligt{' '}
    <Link target="_blank" href="https://www.aller.se/integritetspolicy" content="Aller Medias personuppgiftspolicy" />
  </>
);

export const MabraStandaloneCheckout: typeof StandaloneCheckout = (props) => {
  const { closeTime } = getOcelotConfig('features.payments');

  const paymentStable = useStable({
    closed: {
      time: closeTime,
      copy: 'Anmälan är nu stängd, håll utkik i våra kanaler för nästa tillfälle.',
    },
    form: {
      options: {
        className: 'cts-tracking-object',
        ...withTrackingAttributes({
          category: 'signup_box',
          name: 'klarna_payment',
          extraInfo: [{ campaign: 'mabra_course_21_dagar' }],
        }),
      },
      labels: { email: 'Kontakt', newsletter: 'Jag vill ta del av inspiration och erbjudanden från MåBra.' },
      submitButtonContent: 'Fortsätt till betalning',
      policy: policy,
    },
    props,
  });

  return (
    <Tracking>
      <StandaloneCheckout {...paymentStable} />
    </Tracking>
  );
};
