import { tw } from '@/utils/tw';
import BaseCheckoutTheme from 'base/components/Checkout/theme';

const CheckoutTheme = tw.theme({
  extend: BaseCheckoutTheme,
  slots: {
    closed: [
      'px-8',
      'md:px-16',
      'lg:px-48',
      'text-headline-sm',
      'md:text-headline-xl',
      'mb-8',
      'md:mb-15',
      'mt-3',
      'md:mt-10',
      'text-center',
    ],
  },
});

export default CheckoutTheme;
