import { tw } from '@/utils/tw';
import BaseCheckoutFormTheme from 'base/components/CheckoutForm/theme';

const Checkbox = tw.theme({
  extend: BaseCheckoutFormTheme.Checkbox,
  slots: {
    label: ['cursor-pointer'],
  },
});

const CheckoutForm = tw.theme({
  extend: BaseCheckoutFormTheme,
  slots: {
    base: [
      'flex',
      'flex-col',
      'px-8',
      'md:px-24',
      'lg:px-58',
      'gap-y-4',
      'm-2',
      'mt-0',
      'sm:m-4',
      'sm:mt-0',
      'lg:m-8',
      'lg:mt-0',
    ],
    policy: ['[&>a]:text-cherry-blossom-500', '[&>a:hover]:underline'],
  },
});

export default Object.assign(CheckoutForm, {
  Button: BaseCheckoutFormTheme.Button,
  Checkbox,
  KlarnaPayment: BaseCheckoutFormTheme.KlarnaPayment,
  TextField: BaseCheckoutFormTheme.TextField,
});
